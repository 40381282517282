import React from 'react';
import i18n from '../../../../../i18n';
import LoadManagement from '../../../components/LoadManagement';

const allowedRoles = [
  'root',
  'solar_admin',
  'property_management',
  'viewer',
  'pv_installer',
  'pv_installer_employee',
  'pv_installer_employee_read_only',
  'pv_installer_employee_read_install',
  'oem',
  'oem_employee',
  'oem_employee_read_only',
  'end_user'
];

export const getColumns = ({ user = {}, myRoleType }) => [
  [{
    title: 'offset',
    titleClasses: 'general-tab-item-title',
    tooltip: 'offsetTooltip',
    isAllowed: allowedRoles.includes(myRoleType),
    Value: () => (
      <>
        <span>
          {user.settings?.offset_watt || 0}
        </span>
        {' '}
        <span className="params">{i18n.t('watt')}</span>
      </>
    )
  },
  {
    title: 'kWPeakOutputLabel',
    titleClasses: 'general-tab-item-title',
    tooltip: 'kWPeakOutputTooltip',
    isAllowed: allowedRoles.includes(myRoleType),
    Value: () => (
      <>
        <span>
          {Math.round((user.settings?.kWp || 0) * 100) / 100}
        </span>
        {' '}
        <span className="params">{i18n.t('kWp')}</span>
      </>
    )
  },
  {
    title: 'houseFuseLabel',
    titleClasses: 'general-tab-item-title',
    tooltip: 'houseFuseTooltip',
    isAllowed: allowedRoles.includes(myRoleType),
    Value: () => (
      <>
        <span>
          {user.settings?.houseFuse || 0}
        </span>
        {' '}
        <span className="params">{i18n.t('houseFuseUnits')}</span>
      </>
    )
  },
  {
    isAllowed: allowedRoles.includes(myRoleType),
    Value: LoadManagement
  }
  ]
];
