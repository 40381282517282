import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_TIMEZONE } from '../../config';

const epicEmptyFunction = (state) => state;

const initialState = {
  smartMeterData: undefined,
  chartData: undefined,
  user: undefined,
  scaleType: null,
  loading: true,
  chart: {}
};

const loadManagementDashboardSlice = createSlice({
  name: 'loadManagementDashboard',
  initialState,
  reducers: {
    sendIOTCommandSuccess: epicEmptyFunction,
    sendIOTCommandError: epicEmptyFunction,
    requestInitUser: epicEmptyFunction,
    sendIOTCommand: epicEmptyFunction,
    getPhasesData: epicEmptyFunction,

    changeDataForRequest: (state, action) => {
      state.chart = action.payload.data;
    },
    receivePhasesData: (state, action) => {
      state.loading = false;
      state.smartMeterData = action.payload.result;
    },
    receiveChartData: (state, action) => {
      state.loading = false;
      state.chartData = action.payload.result;
    },
    changeScaleType: (state, action) => {
      state.scaleType = action.payload.data;
    },
    getChartData: (state) => {
      state.loading = true;
    },
    receiveInitUser: (state, action) => {
      const { gateway, settings, company_logo } = action.payload.result;
      const { sensors } = gateway;
      const smartMeterId = sensors?.find((device) => device?.device_type === 'smart-meter')?._id;

      state.user = {
        houseFuse: settings?.houseFuse,
        timezone: gateway?.timeZone || DEFAULT_TIMEZONE,
        gatewayId: gateway?._id,
        smartMeterId,
        companyLogo: company_logo,
        ...action.payload.result
      };
    }
  }
});

export const {
  changeDataForRequest,
  sendIOTCommandSuccess,
  sendIOTCommandError,
  receivePhasesData,
  receiveChartData,
  receiveInitUser,
  changeScaleType,
  requestInitUser,
  sendIOTCommand,
  getPhasesData,
  getChartData
} = loadManagementDashboardSlice.actions;

export default loadManagementDashboardSlice.reducer;
