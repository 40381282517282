import React from 'react';
import { Collapse } from '@mui/material';
import PropTypes from 'prop-types';

export const Expander = ({ children, expanded }) => (
  <Collapse in={expanded}>
    {children}
  </Collapse>
);

Expander.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired
};
