import fieldsForDevices from './fieldsForDevices';

// https://solarmanager.atlassian.net/wiki/spaces/SD/pages/3276818/Stepcircuit
const addFieldsForDevicesByWarmWaterDevices = {
  '1StepOnOff': {
    0: ['ip', 'relaisNumber'],
    1: ['ip', 'relaisNumber4'],
    2: ['ip', 'relaisNumber'],
    3: ['ip'],
    4: ['User', 'Password', 'SerialNumber', 'relaisNumber2'],
    5: ['ip'],
    6: ['ip'],
    7: ['ip', 'relaisNumber2'],
    8: ['ip'],
    9: ['ip'],
    10: ['ip', 'relaisNumber'],
    11: ['ip', 'relaisNumber2'],
    12: ['ip'],
    13: ['ip', 'relaisNumber4'],
    14: ['ip'],
    15: ['relaisNumber2']
  },
  'Pv Ready Waterheater': {
    0: ['ip', 'relaisNumber'],
    1: ['ip', 'relaisNumber4'],
    2: ['ip', 'relaisNumber'],
    3: ['ip'],
    4: ['User', 'Password', 'SerialNumber', 'relaisNumber2'],
    5: ['ip'],
    6: ['ip'],
    7: ['relaisNumber2'],
    8: ['ip'],
    9: ['ip'],
    10: ['ip', 'relaisNumber'],
    11: ['ip', 'relaisNumber2'],
    12: ['ip'],
    13: ['ip', 'relaisNumber4'],
    14: ['ip'],
    15: ['relaisNumber2']
  },
  '3StepControl': {
    0: ['relaisChooseSet2'],
    1: [],
    2: ['relaisChooseSet2'],
    3: ['relaisChooseSet2'],
    4: []
  }
};
const addFieldsForDevicesFor2StepControl = {
  // [[], []] - first array - before minPower2, second array - after minPower2
  '2StepControl': {
    0: [['ip'], ['relaisChooseSet', 'TwoStepSwitchSetting']],
    1: [['ip'], []],
    2: [['ip'], ['relaisChooseSet', 'TwoStepSwitchSetting']],
    3: [['User', 'Password', 'SerialNumber'], 'TwoStepSwitchSetting'],
    4: [['ip'], ['relaisChooseSet', 'TwoStepSwitchSetting']],
    5: [['ip'], ['TwoStepSwitchSetting']],
    6: [['ip'], ['TwoStepSwitchSetting']],
    7: [[], ['TwoStepSwitchSetting']]
  }
};

// https://solarmanager.atlassian.net/wiki/spaces/SD/pages/2293786/PV+SG-Ready
const addFieldsForDevicesByHeatPumpChargingMode = {
  'PV Ready Switch': {
    0: ['ip', 'relaisNumber'],
    1: ['ip', 'relaisNumber4'],
    2: ['ip', 'relaisNumber'],
    3: ['ip'],
    4: ['User', 'Password', 'SerialNumber', 'relaisNumber2'],
    5: ['ip'],
    6: ['ip'],
    7: ['ip', 'relaisNumber2'],
    8: ['ip'],
    9: ['ip'],
    10: ['ip', 'relaisNumber'],
    11: ['ip', 'relaisNumber2'],
    12: ['ip'],
    13: ['ip', 'relaisNumber4'],
    14: ['ip'],
    15: ['relaisNumber2']
  },
  'SG Ready Switch': {
    0: ['ip', 'relaisChooseSet'],
    1: ['ip'],
    2: ['ip', 'relaisChooseSet'],
    3: ['User', 'Password', 'SerialNumber'],
    4: ['ip', 'relaisChooseSet'],
    5: ['ip'],
    6: []
  }
};

/**
 * update default field list
 * according from: https://docs.google.com/document/d/1_jeQxuev38R-w6A-43PtRjtf0bxfqN6g
 * @function getUpdatedFieldList
 * @param {string} deviceName Name of switch device
 * @param {Object} data
 * @param {string} type type of device
 * @return {Array}
 */
export const getUpdatedFieldList = (deviceName, data, updatedType) => {
  if (!deviceName || !data) return [];

  const fieldsList = [...(fieldsForDevices[deviceName] || [])];

  if (addFieldsForDevicesFor2StepControl[deviceName]) {
    const additionalFields = addFieldsForDevicesFor2StepControl[deviceName][data.warmWaterDevices2Step ?? 0] || [[], []];
    const [twoStepControl, minPower2, ...rest] = fieldsList;
    return [twoStepControl, ...additionalFields[0], minPower2, ...additionalFields[1], ...rest];
  }

  if (addFieldsForDevicesByWarmWaterDevices[deviceName]) {
    const additionalFields = addFieldsForDevicesByWarmWaterDevices[deviceName][(data.warmWaterDevices ?? data.warmWaterDevices3Step) ?? 0] || [];

    if (typeof additionalFields === 'function') {
      return additionalFields(fieldsList);
    }

    const [warmWaterDevices, ...rest] = fieldsList;
    return [warmWaterDevices, ...additionalFields, ...rest];
  }

  if (addFieldsForDevicesByHeatPumpChargingMode[deviceName]) {
    const additionalFields = addFieldsForDevicesByHeatPumpChargingMode[deviceName][(data.heatPumpPVReady ?? data.heatPumpSGready) ?? 0] || [];
    const [chargingMode, ...rest] = fieldsList;
    return [chargingMode, ...additionalFields, ...rest];
  }

  /**
   * At the moment for the submeter we are also asking for a smart meter position and if it shall be inverted.
   * We have found out that we don't need these two fields for submeters. These two fields should be removed.
   */
  if (updatedType === 'sub-meter') {
    return fieldsList.filter((item) => !['SmartMeterPosition', 'invertMeasurement'].includes(item));
  }

  return fieldsList;
};
