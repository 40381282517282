import convertStringFieldsToFloat from './convertStringFieldsToFloat';
import fieldsForDevices from './fieldsForDevices';

const transformFieldsForAPI = (fields) => {
  const newFields = JSON.parse(JSON.stringify(fields));

  if (newFields.data?.ip) {
    if (fieldsForDevices[newFields.device_group]?.includes('ip')) {
      newFields.ip = newFields.data.ip;
    }
    delete newFields.data.ip;
  }

  if (newFields.createNewTag) {
    if (newFields.createNewTag === newFields.tag) {
      newFields.createNewTag = true;
    } else {
      delete newFields.createNewTag;
    }
  }

  if (newFields.data?.User) {
    newFields.data.User = newFields.data.User.trim();
  }

  return newFields?.data
    ? { ...newFields, data: convertStringFieldsToFloat(newFields.data) }
    : newFields;
};

export default transformFieldsForAPI;
