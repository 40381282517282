import React from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as ModalWindow from '../ModalWrapper';
import { APP_ID, SOLAR_MANAGER_APP_ID } from '../../config';
import logoVariant2 from '../../assets/images/logo-variant-2.png';
import dashboardMini from '../../assets/images/dashboard-mini.png';
import { getPaymentUrlRequest } from '../../containers/Payments/slice';

const PERMISSION_DENY_MODAL_ID = 'PERMISSION_DENY_MODAL_ID';

export const PermissionDeny = () => {
  const dispatch = useDispatch();

  const handleGetAccess = () => {
    dispatch(getPaymentUrlRequest());
  };

  if (APP_ID !== SOLAR_MANAGER_APP_ID && process.env.REACT_APP_STAGES !== 'development') {
    return null;
  }

  return (
    <ModalWindow.ModalWrapper
      modalID={PERMISSION_DENY_MODAL_ID}
      handleClose={() => {}}
      bodyProps={{ className: 'modal-body-v2 permission-deny', sx: { px: 0, py: 5, width: '80%', maxWidth: 1240 } }}
      backdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.20)', backdropFilter: 'blur(8px)' } }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 6 }}>
        <ModalWindow.ModalHeader componentProps={{ sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 2 } }}>
          <img src={logoVariant2} alt="logo" />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h2" component="h2" className="modal-title-v2" sx={{ pb: 0.25 }}>
              Welcome to Solar Manager!
            </Typography>
            <Typography variant="h4" component="p" className="modal-subtitle-v2">
              Streamline the management and oversight of data from your AIT Water Heater
            </Typography>
          </Box>
        </ModalWindow.ModalHeader>

        <ModalWindow.ModalContent componentProps={{ sx: { px: '10%', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 6 } }}>
          <img src={dashboardMini} alt="dashboard" />
          <Typography variant="h4" component="h4" className="modal-content-v2">
            Unlock the full potential of your AIT Water Heater with Solar Manager platform. Experience efficiency, control, and savings like never before. Get started today for a smarter, more sustainable energy future.
            <Typography variant="h6" component="p" className="modal-content-v2 sub-content">
              In order to get access and experience all of the cool features you should choose plan and pay one-time set up fee first
            </Typography>
          </Typography>

        </ModalWindow.ModalContent>

        <ModalWindow.ModalFooter
          componentProps={{ sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 2 } }}
          isOnlyConfirm
          confirmLabel="Get access now"
          onConfirm={handleGetAccess}
        />
      </Box>
    </ModalWindow.ModalWrapper>
  );
};
