import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { QR_CODE_SCANNER_MODAL_ID, closeModalWindow } from '../../containers/ModalWindow/slice';
import { CancelButton, PrimaryButton } from '../../components/UIKit';
import { getModalsSelector } from '../../redux-store/selectors/modals';
import i18n from '../../i18n';

import './index.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  borderRadius: 6
};

const backdropStyle = {
  backgroundColor: 'transparent'
};

export const ModalWrapper = ({
  bodyProps = { className: 'modal-body-v2', sx: style },
  modalID,
  backdropProps = { invisible: true, timeout: 500, sx: backdropStyle },
  handleClose: customOnClose = undefined,
  children
}) => {
  const dispatch = useDispatch();
  const modals = useSelector(getModalsSelector);
  const { [modalID]: modal = {} } = modals || {};
  const { opened = false } = modal;

  const handleClose = () => {
    if (customOnClose) {
      customOnClose();
      return;
    }
    dispatch(closeModalWindow({ modalID }));
  };

  return (
    <Modal
      aria-labelledby="modal-title-v2"
      aria-describedby="modal-description-v2"
      className="modal-window-v2"
      open={opened}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: { ...backdropProps, sx: { ...backdropStyle, ...backdropProps.sx } }
      }}
    >
      <Fade in={opened}>
        <Box {...bodyProps} className={bodyProps.className} sx={{ ...style, ...bodyProps.sx }}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export const ModalHeader = ({
  children,
  ico,
  title,
  componentProps = {}
}) => {
  if (children) {
    return (
      <Box className="modal-header-v2" {...componentProps}>
        {children}
      </Box>
    );
  }

  return (
    <Box className="modal-header-v2" {...componentProps}>
      {ico && <img src={ico} alt="ico" />}
      <Typography variant="h6" component="h2" className="modal-title-v2">
        {i18n.exists(title) ? i18n.t(title) : title}
      </Typography>
    </Box>
  );
};

export const ModalContent = ({ children, title, componentProps = {} }) => {
  if (children) {
    return (
      <Box className="modal-content-v2" {...componentProps}>
        {children}
      </Box>
    );
  }

  return (
    <Box {...componentProps} className="modal-content-v2">
      <Typography sx={{ mt: 2 }}>
        {i18n.exists(title) ? i18n.t(title) : title}
      </Typography>
    </Box>
  );
};

export const ModalFooter = ({
  children,
  componentProps = {},
  onClose,
  onConfirm,
  isOnlyConfirm,
  confirmLabel = ''
}) => {
  const dispatch = useDispatch();

  const handleCancelBtnClick = () => {
    if (onClose) {
      onClose();
      return;
    }

    dispatch(closeModalWindow({ modalID: QR_CODE_SCANNER_MODAL_ID }));
  };

  const handleSave = () => {
    if (onConfirm) {
      onConfirm();
      return;
    }

    dispatch(closeModalWindow({ modalID: QR_CODE_SCANNER_MODAL_ID }));
  };

  if (children) {
    return (
      <Box className="modal-footer-v2" {...componentProps}>
        {children}
      </Box>
    );
  }

  return (
    <Box className="modal-footer-v2" {...componentProps}>
      {!isOnlyConfirm && <CancelButton onClickHandler={handleCancelBtnClick} />}
      <PrimaryButton
        onClickHandler={handleSave}
        i18nLabel={confirmLabel}
      />
    </Box>
  );
};

ModalWrapper.propTypes = {
  bodyProps: PropTypes.instanceOf(Object),
  backdropProps: PropTypes.instanceOf(Object),
  children: PropTypes.node.isRequired,
  modalID: PropTypes.string.isRequired,
  handleClose: PropTypes.func
};

ModalHeader.propTypes = {
  children: PropTypes.node,
  ico: PropTypes.string,
  title: PropTypes.string,
  componentProps: PropTypes.instanceOf(Object)
};

ModalContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  componentProps: PropTypes.instanceOf(Object)
};

ModalFooter.propTypes = {
  children: PropTypes.node,
  componentProps: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isOnlyConfirm: PropTypes.bool,
  confirmLabel: PropTypes.string
};
