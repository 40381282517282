import { createApi } from '@reduxjs/toolkit/query/react';
import { pick } from 'lodash';

import { DOMAINAPI } from '../../../config';
import { api } from '../../../api';
import { allowedInputDevices, allowedInverters } from '../constants';
import i18n from '../../../i18n';

// eslint-disable-next-line space-before-function-paren
const axiosBaseQuery = async ({ url, method, data, params }) => {
  try {
    const result = await api({ url, method, data, params });
    return { data: result };
  } catch (axiosError) {
    const { response = {} } = axiosError || {};

    return { error: { status: axiosError.code, message: response.data?.message || axiosError.message } };
  }
};

export const feedInManagementApi = createApi({
  reducerPath: 'feedInManagementApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['feedIn', 'inverters', 'inputDevices'],
  endpoints: (builder) => ({
    getUserInputDevices: builder.query({
      providesTags: ['inputDevices'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ userID }) => ({ url: `${DOMAINAPI}/sensors/input-devices/${userID}`, method: 'get' }),
      transformResponse: (inputDevices) => inputDevices
        .filter((inputDevice) => allowedInputDevices.includes(inputDevice.device_group))
        .map((inputDevice) => ({ ...inputDevice, value: inputDevice._id, label: inputDevice.description || i18n.t(inputDevice.device_group) }))
    }),

    getUserInverters: builder.query({
      providesTags: ['inverters'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ userID }) => ({ url: `${DOMAINAPI}/sensors/inverters/${userID}`, method: 'get' }),
      transformResponse: (invertors) => invertors
        .filter((invertor) => allowedInverters.includes(invertor.device_group))
        .map((inputDevice) => ({ ...inputDevice, value: inputDevice._id, label: i18n.t(inputDevice.tag?.name || inputDevice.device_group) }))
    }),

    getFeedIn: builder.query({
      providesTags: ['feedIn'],
      refetchOnMountOrArgChange: true,
      query: ({ feedInId }) => ({ url: `/production-regulations/${feedInId}`, method: 'get' }),
      transformResponse: (feedIn) => pick(feedIn, [
        'controlled_devices',
        'fallback',
        'input_device',
        'nominal_power',
        'regulations',
        'limitedFeedIn',
        'installed_power',
        'RippleControlReceiverMode',
        'ExternalFeedInMode',
        'limitedFeedInMode'
      ])
    }),

    createOrUpdateFeedInManagement: builder.mutation({
      invalidatesTags: ['feedIn'],
      refetchOnMountOrArgChange: true,
      query: ({ userID, data, type, feedInId }) => {
        if (type === 'create') {
          return ({ url: `/production-regulations/${userID}`, method: 'post', data });
        }
        return ({ url: `/production-regulations/${feedInId}`, method: 'put', data });
      }
    })
  })
});

export const {
  useGetUserInputDevicesQuery,
  useGetUserInvertersQuery,
  useGetFeedInQuery,
  useCreateOrUpdateFeedInManagementMutation
} = feedInManagementApi;
