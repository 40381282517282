import { EMPTY, from, of } from 'rxjs';
import { catchError, map, switchMap, delay, mergeMap } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { combineEpics, ofType } from 'redux-observable';
import { saveAs } from 'file-saver';

import { handleErrorDetailed, handleError } from '../../api_helper';
import { api } from '../../api';
import * as UserTypes from './constants';
import {
  getIsSupportContractFlagAllowedToBeChanged,
  receiveIsSupportContractFlagAllowedToBeChanged,
  changeInstallationFinishedRequest,
  changeSupportContractsRequest,
  devicesSaveTypesAndNames,
  lastSensorsDataReceived,
  devicesGetTypesAndNames,
  changePrioritySuccess,
  cancelChangePriority,
  fetchLastSensorsData,
  updateLoadManagement,
  userDataSendRequest,
  receiveUserInfo,
  changePriority,
  cancelUserInfo,
  getListOfIP,
  getUserInfo,
  saveIP,
  scanIP,
  getListOfChargers,
  scanChargers,
  saveChargers
} from './slice';

import { addNotification } from '../NotificationGenerator/slice';
import { dataListReloadData } from '../DataList/slice';
import { closeModalWindow, SEARCH_IP_MODAL_ID } from '../ModalWindow/slice';
import { signInCheckJWT } from '../SignIn/slice';
import {
  ACCOUNT_IP_LIST_URL,
  ACCOUNT_IP_SCAN_URL,
  DATA_SENSORS_URL,
  GATEWAY_INSTALLATION_STATUS_URL,
  SENSOR_TYPES_PART_URL,
  USERS_SOLAR_MANAGERS_USERS_URL,
  USERS_SUPPORT_CONTRACT_URL,
  USER_SETTINGS_URL
} from '../../api/apiUrls';

import i18n from '../../i18n';

function getUserInfoEpic($action) {
  return $action.pipe(
    ofType(getUserInfo),
    map((action) => action.payload.userId),
    switchMap((userId) => from(api.get(`${USERS_SOLAR_MANAGERS_USERS_URL}/${userId}`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (result?.error) {
          return of(cancelUserInfo({ message: result.message }));
        }

        return of(receiveUserInfo({ data: result }));
      })
    )
    )
  );
}

function getIsSupportContractFlagAllowedToBeChangedEpic($action) {
  return $action.pipe(
    ofType(getIsSupportContractFlagAllowedToBeChanged),
    map((action) => action.payload.userId),
    switchMap((userId) => from(api.get(`/users/${userId}/is-support-contract-flag-allowed-to-be-changed`)).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        const { isSupportContractFlagAllowedToBeChanged: isAllow } = result;
        if (typeof isAllow === 'boolean') {
          return of(receiveIsSupportContractFlagAllowedToBeChanged({ data: isAllow }));
        }

        return of(receiveIsSupportContractFlagAllowedToBeChanged({ data: false }));
      })
    )
    )
  );
}

function changePriorityEpic($action) {
  return $action.pipe(
    ofType(changePriority),
    map((action) => action.payload.url),
    switchMap((url) => from(api.put(url)).pipe(
      catchError(handleErrorDetailed),
      map((result) => (result && !result.error
        ? changePrioritySuccess()
        : cancelChangePriority({ message: result }))
      )
    )
    )
  );
}

function changePrioritySuccessEpic($action) {
  return $action.pipe(
    ofType(changePrioritySuccess),
    switchMap(() => of(dataListReloadData({ listID: UserTypes.SENSORS_LIST_ID })))
  );
}

function devicesGetTNEpic($action) {
  return $action.pipe(
    ofType(devicesGetTypesAndNames),
    map((action) => action.payload),
    switchMap(({ deviceType, userId, cb }) => {
      const url = `${SENSOR_TYPES_PART_URL}/${deviceType}${userId ? `?userId=${userId}` : ''}`;

      return from(api.get(url)).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error) {
            if (typeof cb === 'function') {
              return of(cb(result), devicesSaveTypesAndNames({ data: result }));
            }

            return of(devicesSaveTypesAndNames({ data: result }));
          }

          return addNotification({ type: 'error', text: result.message });
        })
      );
    })
  );
}

function scanIPEpic($action) {
  return $action.pipe(
    ofType(scanIP),
    map((action) => action.payload),
    switchMap(({ gateway, device }) => from(
      api.post(`${ACCOUNT_IP_SCAN_URL}${gateway}`, {
        type: device.type,
        name: device.device_group
      })
    ).pipe(
      catchError(handleErrorDetailed),
      map((result) => {
        if (!result.error) {
          return getListOfIP({ gateway });
        }

        return addNotification({ type: 'error', text: result.message });
      })
    )
    )
  );
}
function scanChargersEpic($action) {
  return $action.pipe(
    ofType(scanChargers),
    map((action) => action.payload),
    switchMap((gateway) => from(api.get(`/chargers/scan/${gateway}`, {})).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (!result.error) {
          return of(getListOfChargers({ gateway }), saveChargers({ chargers: result }));
        }

        return addNotification({ type: 'error', text: result.message });
      })
    )
    )
  );
}

let getIPAttemptsMade = 0;
function getListOfIPEpic($action, $state) {
  return $action.pipe(
    ofType(getListOfIP),
    map((action) => action.payload),
    switchMap(({ gateway }) => {
      const {
        modals: {
          searchIP: { opened }
        }
      } = $state.value;

      return from(api.get(`${ACCOUNT_IP_LIST_URL}${gateway}`, { headers: { 'api-version': 2 } })).pipe(
        delay(5000),
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (!result.error) {
            getIPAttemptsMade += 1;

            if (getIPAttemptsMade > 12) {
              getIPAttemptsMade = 0;
              return of(
                addNotification({ type: 'error', text: i18n.t('no IP found') }),
                closeModalWindow({ modalID: SEARCH_IP_MODAL_ID })
              );
            }

            if (isEmpty(result) && opened) {
              return of(getListOfIP({ gateway }));
            }

            return of(saveIP({ ips: result }));
          }

          return of(addNotification({ type: 'error', text: result.message }));
        })
      );
    })
  );
}

// FIXME: check if we need this epic - it was unused -> 790f89fc69c4c0a6995dda1db4cfb8ee0fccb682
// find it and check in next components: ViewByAdmins/ViewByEndUsers
function userDataSendRequestEpic($action) {
  return $action.pipe(
    ofType(userDataSendRequest),
    map((action) => action.payload),
    switchMap(({ method, url, config, onResult = {}, modalID }) => from(api.request({ method, url, ...config })).pipe(
      catchError(handleErrorDetailed),
      mergeMap((result) => {
        if (typeof onResult.callback === 'function') {
          return of(onResult.callback(result));
        }
        if (typeof onResult.cb === 'function') onResult.cb(result);

        let fileReceived;
        if (onResult.downloadFile) {
          if (result instanceof Blob) {
            fileReceived = true;
            saveAs(result, `sm-manager-${Date.now()}.csv`);
          }
        }

        if (result.ok || !result.error || fileReceived) {
          if (!onResult.successMessage) return EMPTY;
          return of(
            closeModalWindow({ modalID }),
            addNotification({ type: 'success', text: i18n.t(onResult.successMessage) })
          );
        }

        if (!onResult.errorMessage) return EMPTY;
        return of(
          closeModalWindow({ modalID }),
          addNotification({ type: 'error', text: i18n.t(onResult.errorMessage) })
        );
      })
    )
    )
  );
}

function sendSupportContracts(action$) {
  return action$.pipe(
    ofType(changeSupportContractsRequest),
    map((action) => action.payload),
    switchMap(({ userId, supportContractFlag }) => from(api.put(`${USERS_SUPPORT_CONTRACT_URL}/${userId}`, { supportContractFlag })).pipe(
      catchError(handleError),
      mergeMap((result) => {
        if (result?.ok === true) {
          return of(
            getUserInfo({ userId }),
            addNotification({ type: 'success', text: i18n.t('success') })
          );
        }

        return of(
          addNotification({ type: 'error', text: i18n.t('error') })
        );
      })
    )
    )
  );
}

function sendInstallationFinished(action$) {
  return action$.pipe(
    ofType(changeInstallationFinishedRequest),
    map((action) => action.payload),
    switchMap(({ gatewayId, isInstallationCompleted, userId }) => from(
      api.put(`${GATEWAY_INSTALLATION_STATUS_URL}/${gatewayId}`, { isInstallationCompleted })
    ).pipe(
      catchError(handleError),
      mergeMap((result) => {
        if (typeof result === 'object') {
          return of(getUserInfo({ userId }), addNotification({ type: 'success', text: i18n.t('success') }));
        }

        return of(addNotification({ type: 'error', text: i18n.t('error') }));
      })
    )
    )
  );
}

function sendLoadManagement(action$) {
  return action$.pipe(
    ofType(updateLoadManagement),
    map((action) => action.payload),
    switchMap(({ loadManagement, houseFuse, myRoleType, userId }) => from(
      api.put(`${USER_SETTINGS_URL}/${myRoleType === 'end_user' ? 'my' : userId}`, {
        loadManagement,
        houseFuse
      })
    ).pipe(
      catchError(handleError),
      mergeMap((result) => {
        if (!result || result?.error || typeof result === 'string') {
          return of(addNotification({ type: 'error', text: i18n.t('error') }));
        }

        return of(
          myRoleType === 'end_user' ? signInCheckJWT() : getUserInfo({ userId }),
          addNotification({ type: 'success', text: i18n.t('success') })
        );
      })
    )
    )
  );
}

function fetchLastSensorsDataEpic($action) {
  return $action.pipe(
    ofType(fetchLastSensorsData),
    map((action) => action.payload),
    switchMap(({ myRoleType, gatewayId }) => from(api.get(`${DATA_SENSORS_URL}/${myRoleType === 'end_user' ? 'my' : gatewayId}`)).pipe(
      catchError(handleErrorDetailed),
      map((result) => lastSensorsDataReceived({ data: result?.data }))
    )
    )
  );
}

export default combineEpics(
  getUserInfoEpic,
  changePriorityEpic,
  changePrioritySuccessEpic,
  devicesGetTNEpic,
  scanChargersEpic,
  scanIPEpic,
  getListOfIPEpic,
  userDataSendRequestEpic,
  sendSupportContracts,
  sendInstallationFinished,
  getIsSupportContractFlagAllowedToBeChangedEpic,
  sendLoadManagement,
  fetchLastSensorsDataEpic
);
