import { createSelector } from '@reduxjs/toolkit';
import { formValueSelector } from 'redux-form';

import { getUniqueTypesList } from '../utils';
import { getDataListDeviceTagsData } from '../../../redux-store/selectors/dataList';
import { addDeviceFormId, allowedAffectedDeviceTypes, editDeviceFormId } from '../constants';
import {
  getDevicesTableDevicesTypesList,
  getDevicesTableUsersSensors,
  getDevicesTableSensorsConfigurationFile
} from '../../../redux-store/selectors/devicesTable';
import { getUserDevicesUserGatewaySensorsSelector } from '../../../redux-store/selectors/userDevices';
import { getDashboardUserChart_SettingsSelector } from '../../../redux-store/selectors/dashboard';
import {
  getSignInUserGatewaySensorsSelector,
  getSignInUserRoleTypeSelector
} from '../../../redux-store/selectors/signIn';

import i18n from '../../../i18n';
import { getUserLicenseSelector } from '../selectors';

export const affectedDevicesOptionsSelector = createSelector(
  [getDevicesTableUsersSensors, getDataListDeviceTagsData],
  (sensors) => sensors
    .filter(({ type }) => allowedAffectedDeviceTypes.includes(type))
    .map(({ _id, device_group, tag }) => ({
      label: tag ? tag.name : i18n.t(device_group),
      value: _id
    }))
);

export const inputDevicesOptionsSelector = createSelector(
  [getDevicesTableUsersSensors],
  (sensors) => sensors
    .filter(({ type }) => ['Input Device'].includes(type))
    .map(({ _id, device_group, description }) => ({
      label: description || i18n.t(device_group),
      value: _id
    }))
);

export const isExpiredSensorsConfigurationFileSelector = createSelector(
  [getDevicesTableSensorsConfigurationFile],
  (data) => {
    const { createdAt } = data || {};
    // eslint-disable-next-line no-unsafe-optional-chaining
    const expiredConfigDate = new Date(createdAt?.setDate(createdAt?.getDate() + 1));
    const currentDate = new Date();
    return currentDate > expiredConfigDate;
  }
);

export const getArrayOfUserColors = createSelector(
  [
    getUserDevicesUserGatewaySensorsSelector,
    getSignInUserGatewaySensorsSelector,
    getSignInUserRoleTypeSelector
  ],
  (deviceSensors, userSensors, roleType) => {
    if (roleType === 'end_user') {
      return userSensors?.map(({ color }) => color || '');
    }
    return deviceSensors?.map(({ color }) => color || '');
  }
);

export const getDashboardUserChart_SettingsColors = createSelector(
  [getDashboardUserChart_SettingsSelector],
  (chart_settings) => {
    const {
      temperatures = [],
      devicesCurrentPower = [],
      invertersCurrentPower = [],
      batteriesSoc = []
    } = chart_settings || {};

    const temperatureColors = temperatures.map(({ color }) => color || '');
    const devicesCurrentPowerColors = devicesCurrentPower.map(({ color }) => color || '');
    const invertersCurrentPowerColors = invertersCurrentPower.map(({ color }) => color || '');
    const batteriesSocColors = batteriesSoc.map(({ color }) => color || '');

    return [
      ...temperatureColors,
      ...devicesCurrentPowerColors,
      ...invertersCurrentPowerColors,
      ...batteriesSocColors
    ];
  }
);

export const devicesTypesList = createSelector(
  [getDevicesTableDevicesTypesList, getUserLicenseSelector],
  (types, license) => {
    const deviceTypes = getUniqueTypesList(types) || [];

    if (license?.restrictions?.allowedDeviceTypes?.length) {
      return deviceTypes.map((type) => {
        if (license.restrictions.allowedDeviceTypes.includes(type.value)) {
          return type;
        }
        return { ...type, disabled: true, isOpenUpgradePopup: true };
      });
    }

    return deviceTypes;
  }
);

/**
 * AddDeviceWindow selectors
 */
const addDeviceFormSelector = formValueSelector(addDeviceFormId);

export const addDeviceFormDevice_group = (state) => addDeviceFormSelector(state, 'device_group');
export const addDeviceFormType = (state) => addDeviceFormSelector(state, 'type');
export const addDeviceFormData = (state) => addDeviceFormSelector(state, 'data');

/**
 * EditDeviceWindow selectors
 */
const editDeviceFormSelector = formValueSelector(editDeviceFormId);

export const editDeviceFormDevice_group = (state) => editDeviceFormSelector(state, 'device_group');
export const editDeviceFormType = (state) => editDeviceFormSelector(state, 'type');
export const editDeviceFormData = (state) => editDeviceFormSelector(state, 'data');
export const editDeviceIsCarExists = (state) => editDeviceFormSelector(state, 'isCarExists');
