import React from 'react';
import PropTypes from 'prop-types';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import i18n from '../../../i18n';

/**
 * Render function for React-Table. SubComponent for company info.
 * @memberof module:ReactTable
 * @param  {object} props - General props object
 * @param  {object} props.original - props of the company details
 * @param  {string} props.original.first_name - Representative's of the company first name
 * @param  {string} props.original.last_name - Representative's of the company last name
 * @param  {string} props.original.email - Company email
 * @param  {string} props.original.street - Company street
 * @param  {string} props.original.city - Company city
 * @param  {string} props.original.zip - Company ZIP
 * @param  {string} props.original.phone - Company phone
 * @param  {string} props.original.webpage - Webpage of company
 */
const CompanyInfoSub = (props) => {
  const {
    original: { first_name, last_name, email, street, city, zip, phone, webpage, country }
  } = props;
  const phoneNumber = formatPhoneNumberIntl(`+${phone}`) || phone;
  return (
    <div className="table-details">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-lg-4 row justify-content-start">
            <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
              {i18n.t('firstName')}
            </div>
            <div className="col-6  table-row-details-td">{first_name}</div>
            {' '}
            <br />
            <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
              {i18n.t('lastName')}
            </div>
            <div className="col-6  table-row-details-td">{last_name}</div>
            {' '}
            <br />
            <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
              {i18n.t('mail')}
            </div>
            <div className="col-6  table-row-details-td email-detail-table">
              {email}
            </div>
            {' '}
            <br />
            <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
              {i18n.t('webpage')}
            </div>
            <div className="col-6  table-row-details-td">{webpage}</div>
          </div>
          <div className="col-md-6  col-lg-4 row justify-content-start">
            <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
              {i18n.t('street')}
            </div>
            <div className="col-6  table-row-details-td">{street}</div>
            {' '}
            <br />
            <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
              {i18n.t('city')}
            </div>
            <div className="col-6  table-row-details-td">{city}</div>
            {' '}
            <br />
            <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
              {i18n.t('zip')}
            </div>
            <div className="col-6  table-row-details-td">{zip}</div>
            <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
              {i18n.t('phone')}
            </div>
            <div className="col-6  table-row-details-td">
              {phoneNumber || ''}
            </div>
            <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
              {i18n.t('country')}
            </div>
            <div className="col-6  table-row-details-td">
              {country || ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyInfoSub.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default CompanyInfoSub;
