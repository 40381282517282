import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { CustomTextInput } from '../../../components/ReduxFormFields';
import { getModalsGeneralSettingsSelector } from '../../../redux-store/selectors/modals';
import { required, offset, validateKWP, validateHouseFuse, validateDecimalPlaces } from '../../validation';
import { GENERAL_SETTINGS_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

const formID = 'editGeneralSettAdm';

const validateDecimalPlacesWith3 = validateDecimalPlaces(3);

/**
 * Modal window for editing general settings of user
 * @memberof module:UserDetails
 */
const EditGeneralSettingsWindow = (props) => {
  const { reset, handleSubmit, pristine } = props;
  const dispatch = useDispatch();

  const modal = useSelector(getModalsGeneralSettingsSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: GENERAL_SETTINGS_MODAL_ID }));

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={GENERAL_SETTINGS_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('generalSettings')}</h5>
      </div>
      <div className="modal-body">
        <form
          id={formID}
          onSubmit={handleSubmit}
          className="m-login__form m-form pop-up-form add-sm-us"
        >
          <div className="form-group m-form__group input-field">
            <Field
              name="offset_watt"
              component={CustomTextInput}
              label={i18n.t('offsetWatt')}
              className="m-input"
              validate={[required, offset]}
              showTooltip={i18n.t('offsetTooltip')}
            />
          </div>
          <div className="form-group m-form__group input-field">
            <Field
              name="kWp"
              component={CustomTextInput}
              label={`${i18n.t('kWPeakOutputLabel')} (${i18n.t('kWp')})`}
              className="m-input"
              validate={[validateKWP, validateDecimalPlacesWith3]}
              showTooltip={i18n.t('kWPeakOutputTooltip')}
            />
          </div>
          <div className="form-group m-form__group input-field">
            <Field
              name="houseFuse"
              component={CustomTextInput}
              label={`${i18n.t('houseFuseLabel')} (${i18n.t('houseFuseUnits')})`}
              className="m-input"
              validate={[validateHouseFuse]}
              showTooltip={i18n.t('houseFuseTooltip')}
            />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
        <PrimaryButton form={formID} disabled={pristine} isSubmit type="save" />
      </div>
    </ModalWindow>
  );
};

EditGeneralSettingsWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: 'EditGeneralSettings'
})(EditGeneralSettingsWindow);

export default form;
