import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { openModalWindow } from '../../../ModalWindow/slice';
import { allowedRolesForInviteUsers, INVITE_MODAL_ID } from '../../constants';

import i18n from '../../../../i18n';

import './index.scss';

const InviteUsersTableHeader = (props) => {
  const { myRoleType, invitedRole } = props;
  const dispatch = useDispatch();

  const inviteUserHandler = () => dispatch(openModalWindow({ modalID: INVITE_MODAL_ID, data: { invitedRole } }));
  const isAllowedToInviteUser = allowedRolesForInviteUsers.includes(myRoleType);

  return (
    <div className="invited-users-header-container">
      {isAllowedToInviteUser ? (
        <button
          type="button"
          onClick={inviteUserHandler}
          className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp invite-btn"
        >
          {i18n.t('inviteUser')}
        </button>
      ) : null}
    </div>
  );
};

InviteUsersTableHeader.propTypes = {
  myRoleType: PropTypes.string.isRequired,
  invitedRole: PropTypes.string.isRequired
};

export default InviteUsersTableHeader;
