/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import compose from 'lodash/fp/compose';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { pick } from 'lodash';
import { GENERAL_SETTINGS_MODAL_ID, modalWindowSend } from '../../ModalWindow/slice';
import EditGeneralSettingsWindow from '../components/EditGeneralSettingsWindow';
import EditTariffWindow from '../components/EditTariffWindow';
import UserInfo from './components/UserInfo';
import { receiveUserInfo } from '../slice';
import { TabPanel, GeneralInfo, Software, SupportTab } from './components';
import { signInUpdateUser } from '../../SignIn/slice';
import { getPagesSettingsSelector } from '../../../redux-store/selectors/pagesSettings';
import { setPagesSetting } from '../../PagesSettings/slice';
import i18n from '../../../i18n';
import { defaultStepsForSeasons, editTariffId, tariffTypes, unitsTypesByCountry } from '../constants';
import { USER_SETTINGS_URL } from '../../../api/apiUrls';
import { CommonInfoModalWindow } from '../../../modals';
import { isInvitedUserHasReadOnlyPermissionSelector, isPvEmployeeInstallHasReadOnlyAccessSelector } from '../../../redux-store/selectors/userDevices';
import './index.scss';

const transformToNumber = (name) => (fields) => ({ ...fields, [name]: Number(fields?.[name]) });

const transformFieldsForAPI = (formFields) => {
  const composer = compose(...['offset_watt', 'kWp', 'houseFuse'].map(transformToNumber));

  if (Number(formFields?.houseFuse) > 0) {
    return composer({ ...formFields });
  }

  return composer({ ...formFields, loadManagement: false });
};

const transformTariffFieldsForAPI = (formFields) => {
  const cloneFormFields = { ...formFields };

  if (cloneFormFields?.singleTariff) {
    cloneFormFields.singleTariff = Number(cloneFormFields?.singleTariff);
  }

  if (cloneFormFields.directMarketing !== 0) {
    cloneFormFields.exportStandardTariff = undefined;
  }

  if (cloneFormFields?.connectedTo) {
    cloneFormFields.connectedTo = undefined;
  }

  if (cloneFormFields?.tariffType !== tariffTypes.dynamic) {
    cloneFormFields.provider = undefined;
    cloneFormFields.oauth2TokenId = undefined;
    cloneFormFields.providerType = undefined;
    cloneFormFields.meteringCode = undefined;
  }

  if (cloneFormFields?.providerType !== 'meteringCode') {
    cloneFormFields.meteringCode = undefined;
  }

  const composer = compose(...['lowTariff', 'highTariff'].map(transformToNumber));
  return composer({
    ...cloneFormFields,
    directMarketing:
      typeof cloneFormFields.directMarketing === 'number'
        ? cloneFormFields.directMarketing
        : cloneFormFields.directMarketing?.value
  });
};

/**
     * Block with user, software and general info. Viewed by everyone except end_user.
     */
const UserDetailsTabs = (props) => {
  const { user, myRoleType } = props;
  const dispatch = useDispatch();
  const { userDetails: userDetailsSettings = {} } = useSelector(getPagesSettingsSelector);
  const isReadOnlyPermission = useSelector(isInvitedUserHasReadOnlyPermissionSelector);
  const isPvEmployeeInstallHasReadOnlyAccess = useSelector(isPvEmployeeInstallHasReadOnlyAccessSelector);

  // eslint-disable-next-line arrow-body-style
  const updateSettings = (response) => {
    return myRoleType === 'end_user'
      ? signInUpdateUser({ user: { ...user, settings: { ...response } } })
      : receiveUserInfo({ data: { ...user, settings: { ...response } } });
  };

  const changeGeneralSettings = (fields, modalID, transform) => dispatch(
    modalWindowSend({
      modalID,
      requestConfig: {
        method: 'put',
        url: `${USER_SETTINGS_URL}/${myRoleType === 'end_user' ? 'my' : user?._id}`,
        data: transform(fields)
      },
      cb: updateSettings
    })
  );

  const handleTabChange = (_, value) => dispatch(setPagesSetting({ page: 'userDetails', data: { userInfoActiveTab: value } }));

  const tabProps = { user, myRoleType, isReadOnlyPermission: isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess };

  return (
    <>
      <CommonInfoModalWindow
        modalID="requireMoreTimeID"
        isShowEachTime
        label="requireMoreTimeLabel"
        info="requireMoreTimeInfo"
      />
      <EditGeneralSettingsWindow
        onSubmit={(data) => changeGeneralSettings(data, GENERAL_SETTINGS_MODAL_ID, transformFieldsForAPI)}
        initialValues={pick(user?.settings, ['offset_watt', 'kWp', 'houseFuse'])}
        enableReinitialize
      />
      {Boolean(user?.settings) && (
        <EditTariffWindow
          onSubmit={(data) => changeGeneralSettings(data, editTariffId, transformTariffFieldsForAPI)}
          myRoleType={myRoleType}
          user={user}
          initialValues={{
            ...defaultStepsForSeasons,
            // only for helion
            exportStandardTariff: user?.settings?.exportTariffs?.standard?.price ?? undefined,
            ...pick(user?.settings, [
              'commonSeasons',
              'winterSeason',
              'tariffType',
              'provider',
              'lowTariff',
              'highTariff',
              'isWinterTimeEnabled',
              'directMarketing',
              'singleTariff',
              'providerType',
              'meteringCode'
            ])
          }}
          unit={unitsTypesByCountry[user?.country?.toLowerCase()] || unitsTypesByCountry.default}
        />
      )}
      <div className="user-settings">
        <Tabs
          value={userDetailsSettings.userInfoActiveTab}
          onChange={handleTabChange}
          textColor="inherit"
          sx={{ px: 2 }}
        >
          <Tab disableRipple value={0} label={i18n.t('tabEndUserInfo')} />
          <Tab disableRipple value={1} label={i18n.t('generalSettings')} />
          <Tab disableRipple value={2} label={i18n.t('software')} />
          {['root', 'solar_admin'].includes(myRoleType) && <Tab disableRipple value={3} label={i18n.t('support')} />}

        </Tabs>

        <TabPanel classes="main-info-tab" value={userDetailsSettings.userInfoActiveTab} index={0}>
          <UserInfo label={i18n.t('tabEndUserInfo')} {...tabProps} />
        </TabPanel>
        <TabPanel value={userDetailsSettings.userInfoActiveTab} index={1}>
          <GeneralInfo label={i18n.t('generalSettings')} {...tabProps} />
        </TabPanel>
        <TabPanel value={userDetailsSettings.userInfoActiveTab} index={2}>
          <Software label={i18n.t('software')} {...tabProps} />
        </TabPanel>
        {['root', 'solar_admin'].includes(myRoleType) && (
          <TabPanel value={userDetailsSettings.userInfoActiveTab} index={3}>
            <SupportTab label={i18n.t('support')} {...tabProps} />
          </TabPanel>
        )}
      </div>
    </>
  );
};

UserDetailsTabs.propTypes = {
  user: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string
};

export default UserDetailsTabs;
