const convertStringFieldsToFloat = (data) => {
  const floatFields = [
    'roomTempOverproduction',
    'roomTempNormal',
    'roomTempReduced',
    'roomTempOverproductionHk2',
    'roomTempNormalHk2',
    'roomTempReducedHk2',
    'zone1OverproductionTemp',
    'zone1NormalTemp',
    'zone1ReducedTemp',
    'zone2OverproductionTemp',
    'zone2NormalTemp',
    'zone2ReducedTemp'
  ];

  return Object.entries(data).reduce(
    (obj, [key, value]) => (floatFields.includes(key)
      ? { ...obj, [key]: Number.parseFloat(value) }
      : { ...obj, [key]: value }),
    {}
  );
};

export default convertStringFieldsToFloat;
