import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openModalWindow } from '../containers/ModalWindow/slice';

export const useOauthFlowCallback = (search, modalId, history) => {
  const dispatch = useDispatch();
  const state = new URLSearchParams(search).get('state');

  useEffect(() => {
    if (state) {
      const stateObj = state?.split(',').reduce((acc, item) => {
        const [key, value] = item.split(':');
        acc[key] = value;
        return acc;
      }, {}) || {};

      const { modalId: modalIdFromState, connected, ...formState } = stateObj;
      if (connected === 'true') {
        formState.connectedTo = formState.provider;
      }

      if (modalIdFromState === modalId) {
        dispatch(openModalWindow({ data: { formData: formState }, modalID: modalId }));
      }

      history.replace(history.location.pathname);
    }
  }, [state, modalId]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
