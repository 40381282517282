import React from 'react';
import i18n from '../../../i18n';

/**
  * Converts a number in Wh to kWh, MWh or GWh
  * @param {number} value The number which will convert
  * @returns {React.ReactElement} returns JSX with converted number
  */
export const ConvertValue = (value) => {
  if (value >= 1000000000) {
    const convertedValue = (value / 1000000000).toFixed(1);
    const isLastDigitEqualToZero = convertedValue.endsWith('0');

    return (
      <div className="value">
        {isLastDigitEqualToZero ? (+convertedValue).toFixed(0) : convertedValue}
        <span className="sub-value">{i18n.t('GWh')}</span>
      </div>
    );
  }

  if (value >= 1000000) {
    const convertedValue = (value / 1000000).toFixed(1);
    const isLastDigitEqualToZero = convertedValue.endsWith('0');

    return (
      <div className="value">
        {isLastDigitEqualToZero ? (+convertedValue).toFixed(0) : convertedValue}
        <span>MWh</span>
      </div>
    );
  }

  if (value >= 1000) {
    const convertedValue = (value / 1000).toFixed(1);
    const isLastDigitEqualToZero = convertedValue.endsWith('0');

    return (
      <div className="value">
        {isLastDigitEqualToZero ? (+convertedValue).toFixed(0) : convertedValue}
        <span>{i18n.t('kwh')}</span>
      </div>
    );
  }

  return (
    <div className="value">
      0
      <span>{i18n.t('kwh')}</span>
    </div>
  );
};
