import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

const getDataList = (state) => state?.dataList;
const getDataListSensors = (state) => state.dataList?.sensors || {};
const getDataListCompaniesData = (state) => state.dataList?.companies?.data;
const getDataListInstalled_sm = (state) => state.dataList?.installed_sm || {};
const getDataListMonitoringData = (state) => state.dataList?.monitoring?.data;
const getDataListCurrentViewer = (state) => state.dataList?.currentViewer || {};
const getDataListCurrentPropMgmt = (state) => state.dataList?.currentPropMgmt || {};
const getDataListDeviceTagsData = (state) => state.dataList?.deviceTags?.data || [];
const getDataListCompany_sm_oem = (state) => state.dataList?.company_sm_oem?.data || null;
const getDataListCurrent_companyData = (state) => state.dataList?.current_company?.data;
const getDataListPredictionChartLoading = (state) => get(state, ['dataList', 'predictionChart', 'loading'], true);

const getSmSubUsers = (state) => state.dataList?.sm_sub_users || {};

export const getDataListSelector = createSelector([getDataList], (dataList) => dataList);
export const companyDataListSelector = createSelector([getDataList], (dataList) => dataList?.current_company || {});
export const companyDataListCarsSelector = createSelector([getDataList], (dataList) => dataList?.cars || {});

export const getDataListSensorsSelector = createSelector([getDataListSensors], (sensors) => sensors);
export const getDataListSensorsDataSelector = createSelector([getDataListSensors], (sensors) => sensors?.data || []);

export const getDataListCompaniesDataSelector = createSelector([getDataListCompaniesData], (data) => data || []);
export const getDataListMonitoringDataSelector = createSelector([getDataListMonitoringData], (data) => data);
export const getDataListCompany_sm_oemSelector = createSelector([getDataListCompany_sm_oem], (data) => data || null);
export const getDataListCurrentViewerSelector = createSelector([getDataListCurrentViewer], (viewerDataList) => viewerDataList);
export const getDataListCurrentPropMgmtSelector = createSelector([getDataListCurrentPropMgmt], (currentPropMgmt) => currentPropMgmt);
export const getDataListInstalled_smParamsSelector = createSelector([getDataListInstalled_sm], (installed_sm) => installed_sm?.params);

export const getDataListCurrent_companyData_IdSelector = createSelector([getDataListCurrent_companyData], (data) => data?._id);
export const getDataListCurrent_companyDataOem_IdSelector = createSelector([getDataListCurrent_companyData], (data) => data?.oem?._id);

export const getSmSubUsersSelector = createSelector([getSmSubUsers], (user) => user);

export {
  getDataListDeviceTagsData,
  getDataListPredictionChartLoading
};
