import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import Logo from './Logo';
import Table from './Table';
import i18n from '../../../i18n';
import { Breadcrumbs } from '../../../components';
import { SETTING_PROPERTY_MANAGEMENT_PROFILE__URL } from '../../../api/apiUrls';

const PropMgmtInfo = (props) => {
  const { data = {}, loading } = props;
  const { first_name = '', last_name = '' } = data;

  if (loading) {
    return (
      <div className="whiteStatusText">
        {i18n.t('load')}
        ...
      </div>
    );
  }
  if (!data) {
    return <div className="whiteStatusText">{i18n.t('companyNotFound')}</div>;
  }

  const fullName = `${first_name}${first_name ? ' ' : ''}${last_name}`;
  const { country, phone, status, zip, street, email, city } = data;

  const tableData = [
    [
      {
        label: i18n.t('fName'),
        value: fullName
      },
      {
        label: i18n.t('street'),
        value: street
      }
    ],
    [
      {
        label: i18n.t('mail'),
        value: email,
        type: 'email'
      },
      {
        label: i18n.t('city'),
        value: city
      }
    ],
    [
      {
        label: i18n.t('phone'),
        value: phone && country ? formatPhoneNumberIntl(`+${phone}`) : phone || ''
      },
      {
        label: i18n.t('zip'),
        value: zip
      }
    ],
    [
      {
        label: i18n.t('status'),
        value: i18n.t(status),
        type: 'status'
      },
      {
        label: i18n.t('country'),
        value: i18n.t(country)
      }
    ]
  ];

  return (
    <div className="container-fluid">
      <Breadcrumbs crumbs={[{ name: i18n.t('propMgmt'), url: '/property-managements' }, { name: fullName }]} />

      <div className="container-fluid" style={{ marginBottom: '15px' }}>
        <div className="row align-items-center">
          <div className="company-logo avatar-logo col-auto">
            <Logo url={data?.avatar?.url || false} name={fullName} />
          </div>
          <div className="col-auto mr-auto">
            <Table data={tableData} />
          </div>
          <div className="col-auto align-self-start">
            <Link
              title={i18n.t('editProfile')}
              to={`${SETTING_PROPERTY_MANAGEMENT_PROFILE__URL}/${data.id || data._id}/`}
              className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
            >
              <i className="la la-edit" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

PropMgmtInfo.propTypes = {
  data: PropTypes.instanceOf(Object),
  loading: PropTypes.bool
};

export default PropMgmtInfo;
