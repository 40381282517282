import axios from 'axios';
import Cookies from 'universal-cookie';

import { DOMAINAPI, APP_ID } from '../config';

export const checkOnMaintenanceError = (status) => [500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511, 0].includes(status);

const api = axios.create({
  baseURL: DOMAINAPI,
  headers: {
    'App-Identifier': APP_ID
  }
});

const updateJwt = (newJwt) => {
  if (newJwt) api.defaults.headers.Authorization = newJwt.startsWith('Bearer ') ? newJwt : `Bearer ${newJwt}`;
};

const getJwtFromCookies = () => {
  const cookies = new Cookies();
  const jwt = cookies.get('jwt');
  return jwt;
};

const deleteOldJwt = () => {
  delete api.defaults.headers.Authorization;
  const cookies = new Cookies();
  cookies.remove('jwt', { path: '/' });
};

const updateCookies = (newJwt, cookiesOptions) => {
  const cookies = new Cookies();
  if (Boolean(cookiesOptions) && Object.keys(cookiesOptions).length) {
    cookies.set('jwt', newJwt, { path: '/', ...cookiesOptions });
  } else {
    cookies.set('jwt', newJwt, { path: '/' });
  }
};

export { api, updateJwt, updateCookies, deleteOldJwt, getJwtFromCookies };
